.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/front/app/playground_assets/39799626_logistic-warehouse-flat-color-vector-illustration-1500h.jpg");
  background-position: center;
}
.home-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-container1 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container2 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text1 {
  color: var(--dl-color-gray-900);
  max-width: 600px;
  text-align: center;
}
.home-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}
.home-image1 {
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  display: none;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 479px) {
  .home-container2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}

.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: 75px;
  display: flex;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 1;
  max-width: 1320px;
  align-items: center;
  margin-bottom: 0.75rem;
  flex-direction: row;
  justify-content: space-between;
}
.header-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-heading {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-link {
  display: contents;
}
.header-container1 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  text-decoration: none;
}
.header-text {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
}
.header-root-class-name {
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .header-header {
    max-width: 1320px;
    margin-bottom: var(--dl-space-space-triplequarter);
  }
  .header-text {
    color: var(--dl-color-gray-white);
  }
}
@media(max-width: 767px) {
  .header-root-class-name {
    margin-bottom: 0px;
  }
}

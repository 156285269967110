.footer-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-pimary-900);
}
.footer-container1 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
}
.footer-text1 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-link {
  color: #ffffff;
  font-style: normal;
  transition: 0.3s;
  font-family: Roboto;
  font-weight: 700;
  text-decoration: none;
  background-color: #2d2d2d;
}
.footer-link:hover {
  cursor: pointer;
}
.footer-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: #e3e8efff;
}
.footer-text6 {
  color: var(--dl-color-secondary-500);
  align-self: center;
}
@media(max-width: 991px) {
  .footer-container1 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container2 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-text1 {
    text-align: center;
  }
  .footer-container3 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .footer-text1 {
    text-align: center;
  }
  .footer-text2 {
    text-align: center;
  }
  .footer-text3 {
    text-align: center;
  }
  .footer-text4 {
    text-align: center;
  }
}
